import request from '@/utils/request'


// 查询常见问题列表
export function listQuestion(query) {
  return request({
    url: '/biz/question/list',
    method: 'get',
    params: query
  })
}

// 查询常见问题分页
export function pageQuestion(query) {
  return request({
    url: '/biz/question/page',
    method: 'get',
    params: query
  })
}

// 查询常见问题详细
export function getQuestion(data) {
  return request({
    url: '/biz/question/detail',
    method: 'get',
    params: data
  })
}

// 新增常见问题
export function addQuestion(data) {
  return request({
    url: '/biz/question/add',
    method: 'post',
    data: data
  })
}

// 修改常见问题
export function updateQuestion(data) {
  return request({
    url: '/biz/question/edit',
    method: 'post',
    data: data
  })
}

// 删除常见问题
export function delQuestion(data) {
  return request({
    url: '/biz/question/delete',
    method: 'post',
    data: data
  })
}
